import { z } from 'zod';

const MeasurementsData = z.object({
  totalAreaHectares: z.union([z.string(), z.null()]),
  totalPerimeterMeters: z.union([z.string(), z.null()]),
  titleNo: z.union([z.string(), z.null()]),
});

const TitlesAtPointData = z.object({
  estateInterest: z.union([z.string(), z.null()]),
  estateInterestCode: z.union([z.string(), z.null()]),
  polyId: z.union([z.string(), z.null()]),
  recordStatus: z.union([z.string(), z.null()]),
  registeredStatus: z.union([z.string(), z.null()]),
  tenure: z.union([z.string(), z.null()]),
  tenureCode: z.union([z.string(), z.null()]),
  titleNumber: z.union([z.string(), z.null()]),
  uprn: z.union([z.string(), z.null()]),
  wkt: z.union([z.string(), z.null()]),
});

const AddressPointData = z.object({
  easting: z.union([z.number(), z.null()]),
  northing: z.union([z.number(), z.null()]),
  orderGuid: z.union([z.string(), z.null()]),
  emptyFeatureData: z.union([z.boolean(), z.null()]),
  titlesAtPoint: z.array(TitlesAtPointData).nullable(),
  titleNumberSelected: z.union([z.string(), z.null()]),
});

const TokenInfoData = z.object({
  authKey: z.union([z.string(), z.null()]),
  reference: z.union([z.string(), z.null()]),
  sessionId: z.union([z.string(), z.null()]),
});

const ConfirmedFeaturesData = z.object({
  features: z.union([z.string(), z.null()]),
  wktFeatures: z.union([z.string(), z.null()]),
  featureCount: z.number(),
  featuresUpdated: z.boolean(),
  modalOpen: z.boolean(),
});

export const StateProps = z.object({
  measurements: MeasurementsData,
  addressPoint: AddressPointData,
  cancelMapping: z.union([z.boolean(), z.null()]),
  confirmedFeatures: ConfirmedFeaturesData,
  tokenInfo: TokenInfoData,
  mappingConfirmed: z.boolean(),
});

export type MeasurementsDataType = z.infer<typeof MeasurementsData>;
export type AddressPointDataType = z.infer<typeof AddressPointData>;
export type TokenInfoDataType = z.infer<typeof TokenInfoData>;
export type ConfirmedFeaturesDataType = z.infer<typeof ConfirmedFeaturesData>;
export type StatePropsType = z.infer<typeof StateProps>;
export type TitlesAtPointType = z.infer<typeof TitlesAtPointData>;

export interface ISiteInformationData {
  easting: number;
  northing: number;
  area: number;
  perimeter: number;
}

export type MapAction =
  | { type: 'floodFill'; value: boolean }
  | { type: 'drawPolygon'; value: boolean }
  | { type: 'deletePolygon'; value: boolean }
  | { type: 'none'; value: boolean };
