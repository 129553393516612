import { Injectable } from '@angular/core';
import { createStore, withProps, select, setProps } from '@ngneat/elf';
import {
  AddressPointDataType,
  ConfirmedFeaturesDataType,
  MeasurementsDataType,
  StatePropsType,
  TokenInfoDataType,
} from '../models/message-data.models';
import { Observable, combineLatest, filter, map, pairwise, take } from 'rxjs';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import deepEqual from 'deep-equal';

const initialState: StatePropsType = {
  measurements: {
    totalAreaHectares: null,
    totalPerimeterMeters: null,
    titleNo: null,
  },
  addressPoint: {
    easting: null,
    northing: null,
    orderGuid: null,
    emptyFeatureData: null,
    titlesAtPoint: null,
    titleNumberSelected: null,
  },
  cancelMapping: null,
  confirmedFeatures: {
    features: null,
    wktFeatures: null,
    featuresUpdated: false,
    featureCount: 0,
    modalOpen: false,
  },
  tokenInfo: {
    authKey: null,
    reference: null,
    sessionId: null,
  },
  mappingConfirmed: false,
};

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private store = createStore(
    { name: 'app' },
    withProps<StatePropsType>(initialState)
  );
  private geoJsonFormat = new GeoJSON();
  private wktFormat = new WKT();
  store$ = this.store;

  public originalAddressPoint: AddressPointDataType = {
    easting: null,
    northing: null,
    orderGuid: null,
    emptyFeatureData: null,
    titlesAtPoint: null,
    titleNumberSelected: null,
  };

  measurements$: Observable<MeasurementsDataType> = this.store.pipe(
    select((state) => state.measurements)
  );
  addressPoint$: Observable<AddressPointDataType> =
    this.createDeepEqualObservable(
      this.store.pipe(select((state) => state.addressPoint))
    );

  createDeepEqualObservable<T extends object>(
    observable: Observable<T>
  ): Observable<T> {
    return observable.pipe(
      pairwise(),
      filter(([prev, curr]) => !deepEqual(prev, curr)),
      map(([_prev, curr]) => curr)
    );
  }
  measurementsAndAddressPoint$: Observable<
    [MeasurementsDataType, AddressPointDataType]
  > = combineLatest([this.measurements$, this.addressPoint$]).pipe(
    filter(([measurements, addressPoint]) => {
      const { totalAreaHectares, totalPerimeterMeters } = measurements;
      const { easting, northing } = addressPoint;

      return (
        totalAreaHectares !== null &&
        totalPerimeterMeters !== null &&
        easting !== null &&
        northing !== null
      );
    })
  );
  cancelMapping$: Observable<boolean | null> = this.store.pipe(
    select((state) => state.cancelMapping)
  );
  confirmedFeatures$: Observable<ConfirmedFeaturesDataType> = this.store.pipe(
    select((state) => state.confirmedFeatures)
  );
  tokenInfo$: Observable<TokenInfoDataType> = this.createDeepEqualObservable(
    this.store.pipe(select((state) => state.tokenInfo))
  );

  /**
   * Updates the store with new state
   * @param {IStateProps} newState - The new state to update the store with
   */
  updateStore(newState: StatePropsType) {
    this.store.update(setProps(newState));
  }

  /**
   * Updates a specific property of the state
   * @template T
   * @param {T} prop - The property to update
   * @param {IStateProps[T]} value - The value to set for the property
   */
  updateStateProperty<T extends keyof StatePropsType>(
    prop: T,
    value: StatePropsType[T]
  ) {
    this.store.update((state) => ({
      ...state,
      [prop]: value,
    }));
  }

  /**
   * Updates the confirmed features in the application state.
   *
   * @param {Feature<Geometry>[]} features - The array of features to be updated.
   * @param {boolean} featuresUpdated - A flag indicating whether the features have been updated.
   * @param {boolean} modalOpen - A flag indicating whether the modal is open.
   */
  updateConfirmedFeatures(
    features: Feature<Geometry>[],
    featuresUpdated: boolean,
    modalOpen: boolean
  ): void {
    const featureCount = features.length;

    let wktFeatures: string | null = null;
    let geoJsonFeatures: string | null = null;

    if (featureCount > 0) {
      wktFeatures = this.wktFormat.writeFeatures(features);
      geoJsonFeatures = this.geoJsonFormat.writeFeatures(features);
    }
    const newState = {
      features: geoJsonFeatures,
      wktFeatures,
      featureCount,
      featuresUpdated,
      modalOpen,
    };
    this.updateStateProperty('confirmedFeatures', newState);
  }

  getCurrentStateSnapshot(): Observable<StatePropsType> {
    return this.store.pipe(take(1));
  }
}
