import { Injectable } from '@angular/core';
import { Store, createStore } from '@ngneat/elf';
import {
  updateEntities,
  deleteEntities,
  deleteAllEntities,
  addEntities,
  withEntities,
  setEntities,
} from '@ngneat/elf-entities';
import { stateHistory } from '@ngneat/elf-state-history';
import { Observable, skip, take } from 'rxjs';

export interface IDrawPolygon {
  id: string;
  feature: string;
  updated: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class PolygonStoreService {
  private readonly polygonStore = createStore(
    { name: 'drawPolygon' },
    withEntities<IDrawPolygon>()
  );

  public readonly polygonStateHistory = stateHistory(this.polygonStore, {
    resetFutureOnNewState: true,
    maxAge: 100
  });

  constructor() {
    // Saved for debugging purposes
    // this.polygonStore.subscribe((val) => {
    //   console.log('current', val);
    //   console.log('past', this.polygonStateHistory.getPast());
    //   console.log('future', this.polygonStateHistory.getFuture());
    // });
  }

  /**
   * Get the current state snapshot of the polygon store
   * @returns {Observable<(typeof this.polygonStore)['state']>} An observable containing the current state of the polygon store
   */
  getCurrentStateSnapshot(): Observable<
    Store<
      {
        name: string;
        state: {
          entities: Record<string, IDrawPolygon>;
          ids: string[];
        };
        config: {
          idKey: 'id';
        };
      },
      {
        entities: Record<string, IDrawPolygon>;
        ids: string[];
      }
    >['state']
  > {
    return this.polygonStore.pipe(take(1));
  }

  /**
   * Updates a polygon with the given featureId and geojsonStr
   * @param {string} featureId - The id of the feature
   * @param {string} geojsonStr - The geojson string of the feature
   */
  updatePolygon(featureId: string, geojsonStr: string): void {
    
    this.polygonStore.update(
      updateEntities(featureId, { feature: geojsonStr, updated: true })
    );
  }

  /**
   * Adds a polygon with the given featureId and geojsonStr
   * @param {string} featureId - The id of the feature
   * @param {string} geojsonStr - The geojson string of the feature
   */
  addPolygon(featureId: string, geojsonStr: string, updated: boolean): void {
    try {
      this.polygonStore.update(
        addEntities([{ id: featureId, feature: geojsonStr, updated }])
      );
    } catch (error) {
      console.warn('Error adding polygon:', error);
    }
  }

  /**
   * Adds multiple polygons
   * @param {IDrawPolygon[]} polygons - The array of polygons to be added
   */
  addPolygons(polygons: IDrawPolygon[]): void {
    this.polygonStore.update(setEntities(polygons));
  }

  /**
   * Removes a polygon with the given featureId
   * @param {string} featureId - The id of the feature
   */
  removePolygon(featureId: string): void {
    this.polygonStore.update(deleteEntities(featureId));
  }

  /**
   * Removes all polygons
   */
  removeAllPolygons(): void {
    this.polygonStore.update(deleteAllEntities());
  }

  /**
   * Returns an observable of the entities and ids
   * @returns {Observable<{entities: Record<string, IDrawPolygon>; ids: string[];}>}
   */
  undoRedoState$(): Observable<{
    entities: Record<string, IDrawPolygon>;
    ids: string[];
  }> {
    return this.polygonStore.pipe(skip(1), take(1));
  }

  /**
   * Undoes the state
   */
  undo(emptyFeaturesData: boolean | null = false): void {
    if (this.polygonStateHistory.getPast().length > 1 || emptyFeaturesData) {
      this.polygonStateHistory.undo();
    } 
  }

  /**
   * Redoes the state
   */
  redo(): void {
    this.polygonStateHistory.redo();
  }
}
