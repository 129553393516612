import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, of, shareReplay, take, tap } from 'rxjs';
import { FeatureCollection } from 'geojson';
import { AppStateService } from './app-state.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient, private appStateService: AppStateService) {}

  /**
   * Sends a post request with the given xml payload
   * @param {string} xmlPayload - The xml payload to send in the request
   * @returns {Observable<any>} - An observable of the response from the server
   */
  sendPostRequest(xmlPayload: string): Observable<any> {
    // Replace with your actual `authkey` parameter
    const authKey: string = localStorage.getItem('authKey') as string;

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/xml')
      .set('authkey', authKey);

    const url =
      'https://conveymapmeta.futureclimateinfo.com/geoserver/dyeanddurham/wfs';

    return this.http.post(url, xmlPayload, { headers });
  }

  /**
   * Retrieves FeatureCollection data from the given requestUrl
   * @param {string} requestUrl - The URL of the request
   * @returns {Observable<FeatureCollection>} An observable containing the FeatureCollection data
   */
  getFciData(requestUrl: string): Observable<FeatureCollection> {
    return this.http.get<FeatureCollection>(requestUrl);
  }

  getFciToken(easting: number, northing: number, orderGuid: string): Observable<any> {
    const data = {
      orderGuid: orderGuid,
      easting: easting,
      northing: northing,
    };

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<any>('/orders/GetMapInfo', data, httpOptions).pipe(
      catchError(this.handleError<any>('getFciToken'))
    );
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${error.status} : ${error.statusText} for GetFciMapInfo`);
      return of(result as T);
    };
  }
}
