import Control from 'ol/control/Control';

export class CancelButton extends Control {
  constructor(onCancelClick: () => void) {
    const button = document.createElement('button');
    button.type = 'button';
    button.textContent = 'Cancel Mapping';

    button.addEventListener('click', onCancelClick, false);

    const element = document.createElement('div');
    element.className = 'cancel-button ol-unselectable em-btn';
    element.appendChild(button);

    super({
      element: element,
      target: undefined,
    });
  }
}
