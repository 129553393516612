import Control from 'ol/control/Control';
import { Map } from 'ol';
type ControlOptions = {
  map?: Map;
  target?: HTMLElement | string;
};

export class LayerControl extends Control {
  constructor(optOptions: ControlOptions) {
    const options = optOptions || {};
    if (!options.map) {
      return;
    }
    const layers = options.map.getLayers().getArray();

    // Create a new div element
    const controlDiv = document.createElement('div');
    controlDiv.className = 'ol-layer-control ol-unselectable ol-control';
    // Add an input element for each layer
    layers.forEach((layer) => {
      const layerDiv = document.createElement('div');
      const input = document.createElement('input');
      input.type = 'checkbox';
      input.checked = layer.getVisible();
      const label = document.createTextNode(layer.get('name'));

      // Add event listener to update layer visibility
      input.addEventListener('change', () => {
        layer.setVisible(!layer.getVisible());
      });

      // Append elements
      layerDiv.appendChild(input);
      layerDiv.appendChild(label);
      controlDiv.appendChild(layerDiv);
    });

    super({
      element: controlDiv,
      target: options.target,
    });
  }
}
