import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GeoService } from 'src/app/services/geo.service';

@Component({
  selector: 'app-edit-map-widget',
  templateUrl: './edit-map-widget.component.html',
  styleUrls: ['./edit-map-widget.component.scss'],
  imports: [FormsModule, CommonModule],
  standalone: true,
})
export class EditMapWidgetComponent {
  constructor(public geoService: GeoService) {}

  titleBoundaryToggle: boolean = true;
  osExtentToggle: boolean = true;

  ngAfterViewInit(): void {
    this.geoService.titleBoundaryToggle.subscribe((value: 'title' | 'osExtent') => {
      this.titleBoundaryToggle = value === 'title';
      this.osExtentToggle = value === 'osExtent';
    });
  }
}
