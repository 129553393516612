import WMTS from 'ol/source/WMTS';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import { optionsFromCapabilities } from 'ol/source/WMTS';
import { getLength, getArea } from 'ol/sphere.js';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Attribution from 'ol/control/Attribution';
import FullScreen from 'ol/control/FullScreen';
import OsmSource from 'ol/source/OSM';
import VectorSource, { VectorSourceEvent } from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { getUid } from 'ol/util';
import {
  defaults as defaultInteractions,
  DoubleClickZoom,
  Interaction,
  Modify,
  Snap,
} from 'ol/interaction';
import { Injectable } from '@angular/core';
import { Feature, MapBrowserEvent, MapEvent } from 'ol';
import { Layer } from 'ol/layer';
import {
  Geometry,
  GeometryCollection,
  MultiPoint,
  MultiPolygon,
} from 'ol/geom';
import { Vector } from '../models/vector';
import { HttpErrorResponse } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  asapScheduler,
  merge,
  throwError,
} from 'rxjs';
import {
  catchError,
  switchMap,
  tap,
  debounceTime,
  filter,
  distinctUntilChanged,
  first,
} from 'rxjs/operators';
import { getCenter } from 'ol/extent';
import proj4 from 'proj4';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { Circle, Fill, Icon, Stroke, Style } from 'ol/style';
import { register } from 'ol/proj/proj4';
import { LayerControl } from '../components/controls/layer-control';
import { ConfirmButton } from '../components/controls/confirm-button';
import { CancelButton } from '../components/controls/cancel-button';
import { Subject, fromEvent, map } from 'rxjs';
import TileWMS from 'ol/source/TileWMS';
import { Point } from 'ol/geom';
import { FeatureCollection } from 'geojson';
import { fromExtent } from 'ol/geom/Polygon';
import { watermarkPng } from '../internal-assets/watermarkPng';
import { markerSvg } from '../internal-assets/markerSvg';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom.js';
import PointerInteraction from 'ol/interaction/Pointer.js';
import { Polygon } from 'ol/geom.js';
import {
  ConfirmedFeaturesDataType,
  ISiteInformationData,
  MeasurementsDataType,
  TokenInfoDataType,
  TitlesAtPointType,
  AddressPointDataType,
} from '../models/message-data.models';
import { HttpService } from './http.service';
import CircleStyle from 'ol/style/Circle';
import { FeatureLike } from 'ol/Feature';
import { Type } from 'ol/geom/Geometry';
import { ModifyEvent } from 'ol/interaction/Modify';
import { IDrawPolygon, PolygonStoreService } from './polygon-store.service';
import { AppStateService } from './app-state.service';
import { WmtsCapabilitiesLayer } from '../models/wmts-capabilities-layer.model';
import * as olExtent from 'ol/extent';
import WKT from 'ol/format/WKT';
import { Coordinate } from 'ol/coordinate';
import booleanIntersects from '@turf/boolean-intersects';
import union from '@turf/union';
import {
  polygon as TurfPolygon,
  multiPolygon as TurfMultiPolygon,
  difference,
} from '@turf/turf';
import { EventTypes } from 'ol/Observable';
import { BaseLayerObjectEventTypes } from 'ol/layer/Base';
import { LayerEventType } from 'ol/layer/Layer';
import { LayerRenderEventTypes } from 'ol/render/EventType';
import { VectorSourceEventTypes } from 'ol/source/VectorEventType';
import { environment } from 'src/environments/environment';

@Injectable()
export class GeoService {
  environment: 'core' | 'spider' = 'spider';
  mapMode: 'viewOnly' | 'draw' | 'select' = 'viewOnly';
  private modalOpen = false;
  private requestTriggerTitles$ = new Subject<{
    requestUrl: string;
    targetLayer: VectorLayer<VectorSource<Feature<Geometry>>>;
  }>();
  private requestTriggerOsExtent$ = new Subject<{
    requestUrl: string;
    targetLayer: VectorLayer<VectorSource<Feature<Geometry>>>;
  }>();
  readonly map: Map;
  allLayers: (
    | TileLayer<OsmSource | TileWMS | WMTS>
    | VectorLayer<VectorSource<Feature<Geometry>>>
  )[];
  private readonly tileVectorLayer: TileLayer<OsmSource | TileWMS>;
  private tileVectorSource = new TileWMS();
  private readonly titleVectorLayer: VectorLayer<
    VectorSource<Feature<Geometry>>
  >;
  private titleVectorSource = new VectorSource();
  private readonly osExtentVectorLayer: VectorLayer<
    VectorSource<Feature<Geometry>>
  >;
  private osExtentVectorSource = new VectorSource();
  private readonly selectedVectorLayer: VectorLayer<
    VectorSource<Feature<Geometry>>
  >;
  private selectedVectorSource = new VectorSource();
  private readonly markerVectorLayer: VectorLayer<
    VectorSource<Feature<Geometry>>
  >;
  private markerVectorSource = new VectorSource();
  private readonly watermarkVectorLayer: VectorLayer<
    VectorSource<Feature<Geometry>>
  >;
  private watermarkVectorSource = new VectorSource();
  private draw?: Draw;
  private modify?: Modify;
  private addressPointFeature!: Feature<Point>;
  // Red theme
  private readonly addressPolygonTheme: [number, number, number] = [255, 0, 0];
  // Blue theme
  private readonly customPolygonTheme: [number, number, number] = [0, 127, 177];
  private readonly titlePolygonTheme: [number, number, number] = [255, 42, 0];
  private readonly highlightStyle = this.createStyle(
    this.addressPolygonTheme,
    1,
    2
  );
  private readonly dummyStyle = this.createStyle([0, 0, 0], 0, 0);
  // These dummy styles are needeed to please TS
  private readonly drawingStyles = {
    Point: this.dummyStyle,
    LineString: this.dummyStyle,
    Polygon: this.createStyle(this.addressPolygonTheme, 2, 2),
    Circle: this.dummyStyle,
    LinearRing: this.dummyStyle,
    MultiPoint: this.dummyStyle,
    MultiLineString: this.dummyStyle,
    MultiPolygon: this.dummyStyle,
    GeometryCollection: this.dummyStyle,
  };
  private findPolygonsAroundAddressPoint = true;
  private emptyFeaturesOnInit = false;
  private tokenInfo!: TokenInfoDataType;
  public initPoint: [number, number] | null = null;
  public floodFillToggle: boolean = false;
  siteInformationData = new Subject<ISiteInformationData>();
  private geoJsonFormat = new GeoJSON();
  private wktFormat = new WKT();
  private stopUpdatingPolygons = false;
  private addressPoint: AddressPointDataType | null = null;
  private vectorLayerToSelect!: VectorLayer<VectorSource<Feature<Geometry>>>;

  private boundaryToggle$ = new BehaviorSubject<'title' | 'osExtent'>('title');
  titleBoundaryToggle = this.boundaryToggle$.asObservable();
  private osExtentToggle$ = new BehaviorSubject<boolean>(false);
  osExtent = this.osExtentToggle$.asObservable();
  zoomLevel$ = new BehaviorSubject<number>(20);
  private formattedExtent: string = '';

  private titlePostrenderEvents$: Observable<MapEvent> = new Observable();
  private mapRenderComplete$: Observable<MapEvent> = new Observable();

  resolveBoundryToggle(value: boolean, toggle: 'title' | 'osExtent') {
    if ((toggle === 'title' && value) || (toggle === 'osExtent' && !value)) {
      this.vectorLayerToSelect = this.titleVectorLayer;
      this.boundaryToggle$.next('title');
    } else if (
      (toggle === 'title' && !value) ||
      (toggle === 'osExtent' && value)
    ) {
      this.vectorLayerToSelect = this.osExtentVectorLayer;
      this.boundaryToggle$.next('osExtent');
    }
  }

  private readonly baseUrlOsExtent = environment.baseUrlOsExtent;

  private readonly baseUrlTitleBoundries = environment.baseUrlTitleBoundries;

  private readonly baseUrlTiles = environment.baseUrlTiles;

  isSatelliteView = false;

  private clearCurrentFeaturesOnAddressPointUpdate = true;

  constructor(
    private httpService: HttpService,
    private polygonStoreService: PolygonStoreService,
    private appStateService: AppStateService
  ) {
    this.initalizeProjection();
    this.initalizeStoreSubscriptions();

    this.tileVectorLayer = new TileLayer({
      source: this.tileVectorSource,
      className: 'tile-layer',
    });

    this.titleVectorLayer = new VectorLayer({
      source: this.titleVectorSource,
      style: this.createStyle(this.titlePolygonTheme, 0, 0),
      className: 'title-layer',
    });
    this.titleVectorLayer.set('name', 'TitleInfo');
    this.vectorLayerToSelect = this.titleVectorLayer;
    this.osExtentVectorLayer = new VectorLayer({
      source: this.osExtentVectorSource,
      style: this.createStyle(this.titlePolygonTheme, 0, 0),
      className: 'os-extent-layer',
    });
    this.osExtentVectorLayer.set(
      'name',
      'MasterMap_WFS:MasterMap_Live_topographicarea'
    );
    this.selectedVectorLayer = new VectorLayer({
      source: this.selectedVectorSource,
      className: 'selected-layer',
    });

    this.watermarkVectorLayer = new VectorLayer({
      source: this.watermarkVectorSource,
      className: 'watermark-layer',
      opacity: 1,
      zIndex: 1000004,
    });

    this.markerVectorLayer = new VectorLayer({
      source: this.markerVectorSource,
      style: new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + markerSvg,
          scale: 0.5,
        }),
      }),
    });

    this.allLayers = [
      this.tileVectorLayer,
      this.titleVectorLayer,
      this.osExtentVectorLayer,
      this.selectedVectorLayer,
      this.markerVectorLayer,
      this.watermarkVectorLayer,
    ];

    const interactions = this.initializeMapInteractions();

    this.map = new Map({
      interactions,
      layers: this.allLayers,
      view: new View({
        constrainResolution: true,
        projection: 'EPSG:27700',
        zoom: 16,
        minZoom: 16,
        maxZoom: 24,
      }),
    });

    // Create an observable that captures postrender events
    this.titlePostrenderEvents$ = this.layerEventObservableFactory(
      this.titleVectorLayer,
      ['postrender']
    );

    this.mapRenderComplete$ = this.mapObservableFactory(['rendercomplete']);

    this.titlePostrenderEvents$.pipe(debounceTime(100)).subscribe(() => {
      if (
        !this.emptyFeaturesOnInit &&
        this.findPolygonsAroundAddressPoint &&
        this.addressPoint &&
        this.addressPoint.titlesAtPoint
      ) {
        this.stopUpdatingPolygons = false;
        this.addTitlePolygon(
          this.addressPoint.titlesAtPoint,
          this.addressPoint.titleNumberSelected
        );
        this.storePolygon();
        this.zoomToFeatures();
      }
    });

    this.handleRequest(this.requestTriggerTitles$).subscribe();
    this.handleRequest(this.requestTriggerOsExtent$).subscribe();
  }

  setupFeatureRequests() {
    if (this.mapMode === 'select') {
      // Request the title boundary polygons when the map is moved
      const moveEndObservable = this.mapObservableFactory(['moveend']);
      moveEndObservable.pipe(debounceTime(200)).subscribe(() => {
        this.sendRequestWithBbox();
      });
    }
  }

  /**
   * onDeletePolygon is a function that handles the deletion of a polygon from the map.
   * @param {MapBrowserEvent<any>} e - The event object containing information about the click event.
   */
  onDeletePolygon = (e: MapBrowserEvent<any>) => {
    this.map.forEachFeatureAtPixel(e.pixel, (f, l) => {
      const feature = f as Feature; // Cast to Feature type
      const layer = l as Layer; // Cast to Layer type
      if (layer === this.selectedVectorLayer) {
        this.selectedVectorSource.removeFeature(feature);
        const idToRemove = this.resolvePolygonId(feature);
        this.polygonStoreService.removePolygon(idToRemove);
      }
    });
    this.updateEastingNorthingAndCalculateMeasurements();
  };

  /**
   * Sets the opacity of the title vector layer based on the map mode.
   */
  setTitleLayerOpacity() {
    // Set the opacity of the title vector layer based on the map mode
    // Disable this feature for now
    // const opacity = this.mapMode === 'viewOnly' ? 0 : 1;
    const opacity = this.mapMode === 'viewOnly' ? 0 : 0;
    this.titleVectorLayer.setOpacity(opacity);
    this.osExtentVectorLayer.setOpacity(opacity);
  }

  /**
   * Handles feature selection on the map. If in 'draw' mode, it returns immediately.
   * Otherwise, it toggles the selection of features at the clicked pixel,
   * updating the selected features array, vector source, and polygon store accordingly.
   *
   * @param {MapBrowserEvent<any>} e - The click event object.
   */
  handleFloodFillAction = (e: MapBrowserEvent<any>) => {
    if (this.mapMode === 'draw') {
      return;
    }
    const featuresToAddOrRemove: Feature<Geometry>[] = [];

    let addFeatures = true;

    this.map.forEachFeatureAtPixel(e.pixel, (f, l) => {
      const feature = f as Feature;
      const layer = l as Layer;
      if (layer === this.selectedVectorLayer) {
        addFeatures = false;
      }
      if (layer === this.vectorLayerToSelect) {
        feature.setProperties({ id: this.resolvePolygonId(feature) });
        featuresToAddOrRemove.push(feature);
        this.setEastingNorthingToPolygonPropeties(feature);
        feature.setStyle(addFeatures ? this.highlightStyle : undefined);
      }
    });
    const currentFeatures = this.getPolygonsWithIds();
    if (addFeatures) {
      // Sort features by area in descending order
      featuresToAddOrRemove.sort((a, b) => {
        const areaA = getArea(a.getGeometry() as Polygon);
        const areaB = getArea(b.getGeometry() as Polygon);
        return areaB - areaA;
      });

      // Only add the largest feature
      const largestFeatureToAdd = featuresToAddOrRemove[0];
      if (largestFeatureToAdd === undefined) {
        return;
      }
      const payloadToAdd = [...currentFeatures, largestFeatureToAdd];
      this.calculateMeasurements(payloadToAdd);
      this.mergeOverlappingFeatures(payloadToAdd);
    } else {
      if (featuresToAddOrRemove.length > 0) {
        this.subtractOverlappingFeatures(
          currentFeatures,
          featuresToAddOrRemove
        );
      }
    }
    this.updateEastingNorthing();
  };

  /**
   * Resolves the correct action when the view button is clicked
   */
  resolveSatelliteClick(satelliteView: boolean): void {
    this.isSatelliteView = satelliteView;
    if (satelliteView) {
      this.setupSatelliteLayer();
    } else {
      this.setupTileLayer();
    }
  }

  /**
   * Initializes default OS base tile layer
   */
  setupTileLayer(): void {
    this.map.getLayers().clear();
    this.allLayers = [];
    this.allLayers.push(
      this.tileVectorLayer,
      this.titleVectorLayer,
      this.osExtentVectorLayer,
      this.selectedVectorLayer,
      this.markerVectorLayer,
      this.watermarkVectorLayer
    );

    this.allLayers.forEach((layer) => this.map.addLayer(layer));
    this.updateControls();
  }

  /**
   * Initializes satellite base tile layer
   */
  setupSatelliteLayer(): void {
    const parser = new WMTSCapabilities();

    const resMap = new Map();
    // Saved for when we decide on a way to switch between layers
    // resMap.set("ap5cm", {minResolution: 0, maxResolution: .08});
    // resMap.set("ap12.5cm", {minResolution: .08, maxResolution: .2});
    // resMap.set("ap25cm", {minResolution: .2, maxResolution: Infinity});

    resMap.set('ap25cm', { minResolution: 0, maxResolution: Infinity });

    fetch(
      'https://mapping2.net/.wmts?user=dyedurham&pwd=wrc4203m&service=WMTS&REQUEST=GetCapabilities&VERSION=1.0.0'
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((text) => {
        // Remove erroneous https URLs, they cause a bug with the WMTSCapabilities parser
        const updatedText = text.replace(
          /(?<=<Capabilities[^>]*)(https:)/g,
          'http:'
        );
        const result = parser.read(updatedText);
        const layers = result.Contents.Layer;

        this.map.getLayers().clear();
        this.allLayers = [];

        layers.forEach((layer: WmtsCapabilitiesLayer) => {
          // Only use the low res layer until we decide on a way to switch between them
          if (layer.Identifier !== 'ap25cm') {
            return;
          }

          const options = optionsFromCapabilities(result, {
            layer: layer.Identifier,
            matrixSet: layer.TileMatrixSetLink[0].TileMatrixSet,
          });
          if (options) {
            const wmtsSource = new WMTS({
              ...options,
              attributions: [`© Copyright Bluesky International Ltd`],
              attributionsCollapsible: false,
            });
            const layerRes = resMap.get(layer.Identifier);
            if (layerRes) {
              const wmtsLayer = new TileLayer({
                source: wmtsSource,
                minResolution: layerRes.minResolution,
                maxResolution: layerRes.maxResolution,
              });
              wmtsLayer.set('name', layer.Identifier);
              this.allLayers.push(wmtsLayer);
            } else {
              throw new Error(
                'No resolution found for layer ' + layer.Identifier
              );
            }
          }
        });

        this.allLayers.push(
          this.titleVectorLayer,
          this.osExtentVectorLayer,
          this.selectedVectorLayer,
          this.markerVectorLayer,
          this.watermarkVectorLayer
        );

        this.allLayers.forEach((layer) => this.map.addLayer(layer));
        this.updateControls();
      })
      .catch((error) => {
        console.error(
          'There has been a problem with your fetch operation:',
          error
        );
      });
  }

  /**
   * Initializes store subscriptions
   */
  private initalizeStoreSubscriptions(): void {
    this.appStateService.tokenInfo$
      .pipe(filter((tokenInfo) => !!tokenInfo.authKey))
      .subscribe((tokenInfo) => {
        this.tokenInfo = tokenInfo;
        this.tileVectorLayer.setSource(this.customTileSetup());
      });

    this.appStateService.addressPoint$
      .pipe(filter((addressPoint) => addressPoint.easting !== null))
      .subscribe((addressPoint) => {
        if (!this.appStateService.originalAddressPoint.easting) {
          this.appStateService.originalAddressPoint = addressPoint;
        }
        this.addPoint(
          [addressPoint.easting, addressPoint.northing] as [number, number],
          addressPoint.emptyFeatureData as boolean,
          this.clearCurrentFeaturesOnAddressPointUpdate
        );
        this.addressPoint = addressPoint;

        if (
          this.mapMode === 'select' &&
          this.clearCurrentFeaturesOnAddressPointUpdate
        ) {
          this.sendRequestWithBbox(1);
        }
        if (this.clearCurrentFeaturesOnAddressPointUpdate) {
          this.clearAllVectorLayerFeatures();
          this.findPolygonsAroundAddressPoint = true;
        }
      });

    this.appStateService.confirmedFeatures$.subscribe(
      (confirmedFeatures: ConfirmedFeaturesDataType) => {
        if (this.stopUpdatingPolygons) {
          return;
        }
        this.findPolygonsAroundAddressPoint = false;
        if (!confirmedFeatures.features && !confirmedFeatures.wktFeatures) {
          this.findPolygonsAroundAddressPoint = true;
          this.selectedVectorSource.clear();
          return;
        }
        const deserializedFeatures =
          this.deserializeFeatures(confirmedFeatures);
        this.addPolygonsToMap(deserializedFeatures);
        this.calculateMeasurements(deserializedFeatures);
      }
    );
  }

  /**
   * This method deserializes features from GeoJSON and WKT formats into OpenLayers Feature objects.
   * If the features are in WKT format and represent a GeometryCollection, it will break them down into individual geometries.
   * If there are no WKT features or the WKT features do not form a GeometryCollection, it will return the deserialized GeoJSON features.
   * If there are WKT features and they form a GeometryCollection, it will return the individual geometries as Features.
   *
   * @param {ConfirmedFeaturesDataType} featuresData - The data containing GeoJSON and WKT features to be deserialized.
   * @returns {Feature[]} An array of OpenLayers Feature objects.
   */
  deserializeFeatures(
    featuresData: ConfirmedFeaturesDataType
  ): Feature<Geometry>[] {
    const deserializedFeatures = featuresData.features
      ? (this.geoJsonFormat.readFeatures(
          featuresData.features
        ) as Feature<Geometry>[])
      : ([] as Feature<Geometry>[]);
    let wktFeatures: Feature[] = [];

    if (featuresData.wktFeatures) {
      const collection: Geometry = this.wktFormat.readGeometry(
        featuresData.wktFeatures
      );

      if (collection.getType() === 'GeometryCollection') {
        const geometries = (collection as GeometryCollection).getGeometries();
        wktFeatures = geometries.map((geometry) => new Feature(geometry));
      } else {
        wktFeatures = [new Feature(collection as Geometry)];
      }
    }

    return deserializedFeatures.length > 0 ? deserializedFeatures : wktFeatures;
  }

  /**
   * Adds an array of features to the map. If the array is not empty, it adds the features as confirmed features and zooms to their extent.
   *
   * @param {Feature[]} featuresToAdd - An array of OpenLayers Feature objects to be added to the map.
   */
  addPolygonsToMap(featuresToAdd: Feature[]): void {
    if (featuresToAdd.length > 0) {
      this.addFeaturesToMapAndStore(featuresToAdd);
      this.mapRenderComplete$.pipe(first()).subscribe(() => {
        this.zoomToFeatures();
      });
    }
  }

  /**
   * Updates the feature select interaction based on title boundary toggle
   * @param {boolean} value - The boolean value to determine whether to add or remove the feature select interaction
   */
  updateFeatureSelectInteraction(value: boolean): void {
    value
      ? this.map.on('dblclick', this.handleFloodFillAction)
      : this.map.un('dblclick', this.handleFloodFillAction);
  }

  private updateDeletePolygonInteraction(value: boolean): void {
    if (!this.map) {
      return;
    }
    value
      ? this.map.on('dblclick', this.onDeletePolygon)
      : this.map.un('dblclick', this.onDeletePolygon);
  }

  /**
   * Undo the last map action
   */
  undoMapAction(): void {
    this.setUndoRedoSubscription();
    this.appStateService.getCurrentStateSnapshot().subscribe((state) => {
      this.polygonStoreService.undo(state.addressPoint.emptyFeatureData);
    });
  }

  /**
   * Redo the last action in the map
   */
  redoMapAction(): void {
    this.setUndoRedoSubscription();
    this.polygonStoreService.redo();
  }

  /**
   * Sets up an observable subscription to the undoRedoState$() method of the
   * PolygonStoreService.
   * When a new state is emitted, the selectedVectorSource is cleared and
   * the features from the state are added to it.
   */
  private setUndoRedoSubscription(): void {
    this.polygonStoreService.undoRedoState$().subscribe((state) => {
      this.selectedVectorSource.clear();

      Object.entries(state.entities).forEach(([id, entity]) => {
        if (id === '0') {
          return;
        }
        const feature = this.geoJsonFormat.readFeature(
          entity.feature
        ) as Feature<Geometry>;
        this.applyStyleToFeature(feature, feature.get('title_no'), !!this.draw);
        this.selectedVectorSource.addFeature(feature);
      });
      this.updateEastingNorthingAndCalculateMeasurements();
    });
  }

  /**
   * Resolves the map action based on the given input
   * @param {string} newAction - The new action to be taken.
   */
  resolveMapAction(
    newAction: 'floodFill' | 'drawPolygon' | 'deletePolygon' | 'none'
  ): void {
    const actionMap = {
      floodFill: () => this.setFloodFillMode(),
      drawPolygon: () => this.setDrawPolygonMode(),
      deletePolygon: () => this.setDeletePolygonMode(),
      none: () => this.disableAllActions(),
    };

    if (newAction in actionMap) {
      actionMap[newAction]();
    }
  }

  /**
   * Disables all actions on the map.
   */
  disableAllActions(): void {
    this.updateFeatureSelectInteraction(false);
    this.toggleDrawMode(false);
    this.floodFillToggle = false;
    this.updateDeletePolygonInteraction(false);
  }

  /**
   * Sets the flood fill mode and updates the feature select interaction and delete polygon interaction
   */
  setFloodFillMode(): void {
    this.toggleDrawMode(false);
    this.floodFillToggle = true;
    this.updateFeatureSelectInteraction(true);
    this.updateDeletePolygonInteraction(false);
  }

  /**
   * Sets the draw polygon mode
   */
  setDrawPolygonMode(): void {
    this.updateFeatureSelectInteraction(false);
    this.floodFillToggle = false;
    this.updateDeletePolygonInteraction(false);
    this.toggleDrawMode(true);
  }

  /**
   * Sets the delete polygon mode by disabling the feature select interaction,
   * toggling draw mode off, setting flood fill toggle to false, and enabling the delete polygon interaction
   */
  setDeletePolygonMode(): void {
    this.updateFeatureSelectInteraction(false);
    this.toggleDrawMode(false);
    this.floodFillToggle = false;
    this.updateDeletePolygonInteraction(true);
  }

  /**
   * Initializes the map interactions
   * @returns {Interaction[]} An array of Interactions
   */
  private initializeMapInteractions(): Interaction[] {
    if (this.mapMode === 'viewOnly') {
      return defaultInteractions()
        .getArray()
        .filter(
          (interaction) =>
            !(
              interaction instanceof DoubleClickZoom ||
              interaction instanceof MouseWheelZoom ||
              interaction instanceof PointerInteraction
            )
        );
    } else {
      return defaultInteractions()
        .getArray()
        .filter((interaction) => !(interaction instanceof DoubleClickZoom));
    }
  }

  /**
   * Creates an observable from a layer
   * @param {TileLayer<OsmSource | TileWMS> | VectorLayer<VectorSource<any>>} layer - The layer to create the observable from
   * @param {EventTypes | BaseLayerObjectEventTypes | LayerEventType | LayerRenderEventTypes} eventListener - The event listener string
   * @returns {Observable<any>} An observable that emits events when the specified event is triggered
   */
  layerEventObservableFactory(
    layer: TileLayer<OsmSource | TileWMS> | VectorLayer<VectorSource<any>>,
    eventListener: (
      | EventTypes
      | BaseLayerObjectEventTypes
      | LayerEventType
      | LayerRenderEventTypes
    )[]
  ): Observable<any> {
    return new Observable((observer) => {
      const listenerFunc = (e: any) => {
        observer.next(e);
      };
      layer.on(eventListener, listenerFunc);

      // on complete or error, remove the event listener
      return () => layer.un(eventListener, listenerFunc);
    });
  }

  /**
   * Creates an observable from a layer
   * @param {TileLayer<OsmSource | TileWMS> | VectorLayer<VectorSource<any>>} layer - The layer to create the observable from
   * @param {EventTypes | BaseLayerObjectEventTypes | LayerEventType | LayerRenderEventTypes} eventListener - The event listener string
   * @returns {Observable<VectorSourceEvent>} An observable that emits events when the specified event is triggered
   */
  sourceEventObservableFactory(
    source: VectorSource<Feature<Geometry>>,
    eventListener: (EventTypes | 'propertychange' | VectorSourceEventTypes)[]
  ): Observable<VectorSourceEvent> {
    return new Observable((observer) => {
      const listenerFunc = (e: any) => {
        observer.next(e);
      };
      source.on(eventListener, listenerFunc);

      // on complete or error, remove the event listener
      return () => source.un(eventListener, listenerFunc);
    });
  }

  /**
   * Creates an observable from a map event
   * @param {Types} mapEvent - The type of map event to listen for
   * @returns {Observable<MapEvent>} An observable that emits the event data when the event is triggered
   */
  mapObservableFactory(
    mapEvent: (
      | 'change'
      | 'error'
      | 'moveend'
      | 'postrender'
      | 'change:layergroup'
      | 'change:size'
      | 'change:target'
      | 'change:view'
      | 'singleclick'
      | 'click'
      | 'dblclick'
      | 'pointerdrag'
      | 'rendercomplete'
    )[]
  ): Observable<MapEvent> {
    return new Observable((observer) => {
      const listenerFunc = (e: any) => {
        observer.next(e);
      };
      this.map.on(mapEvent, listenerFunc);

      // on complete or error, remove the event listener
      return () => this.map.un(mapEvent, listenerFunc);
    });
  }

  /**
   * Updates the controls of the map based on the current map mode.
   */
  public updateControls(): void {
    this.map.getControls().clear();
    const controls =
      this.mapMode === 'viewOnly'
        ? [
            new Attribution({
              collapsible: false,
            }),
          ]
        : [
            new Attribution({
              collapsible: false,
            }),
            new ConfirmButton(() => this.confirmMapping()),
            new CancelButton(() => this.cancelMapping()),
            // Left in for debugging, shows the layer toggles on the map
            // new LayerControl( { map: this.map }),
          ];
    if (this.environment === 'core') {
      controls.push(new FullScreen());
    }
    controls.forEach((control) => {
      this.map.addControl(control);
    });
  }

  /**
   * Updates the interactions of the map based on the map mode
   */
  public updateInteractions(): void {
    this.map.getInteractions().clear();
    const interactions = this.initializeMapInteractions();
    interactions.forEach((interaction) => {
      this.map.addInteraction(interaction);
    });
  }

  /**
   * Adds a layer control to the map for debugging purposes
   */
  public addDebugLayerControl(): void {
    let layerControl = new LayerControl({ map: this.map });
    this.map.addControl(layerControl);
  }

  /**
   * Centers the map on the address point feature
   */
  centerMapOnAddressPointFeature(): void {
    if (this.addressPointFeature && this.addressPointFeature.getGeometry()) {
      const point = this.addressPointFeature.getGeometry() as Point;
      const coordinates = point.getCoordinates();
      this.map.getView().animate({ center: coordinates });
    }
  }

  /**
   * Subtracts overlapping features from existing ones and updates the map.
   * It checks for intersections, subtracts intersecting features, removes them from the map,
   * and recalculates the measurements of the remaining features.
   *
   * @param {Feature[]} existingFeatures - The existing features.
   * @param {Feature[]} featuresToSubtract - The features to subtract.
   * @param {string[]} featureIdsToRemove - The IDs of the features to remove.
   */
  subtractOverlappingFeatures(
    existingFeatures: Feature[],
    featuresToSubtract: Feature[]
  ): void {
    const geojsonExistingFeatures =
      this.convertFeaturesToGeoJson(existingFeatures);
    const geojsonFeaturesToSubtract =
      this.convertFeaturesToGeoJson(featuresToSubtract);
    const featureIdsToRemove: string[] = [];
    let subtractedFeatures = geojsonExistingFeatures.map((existingFeature) => {
      let subtractedFeature = existingFeature;
      geojsonFeaturesToSubtract.forEach((featureToSubtract) => {
        if (booleanIntersects(subtractedFeature, featureToSubtract)) {
          featureIdsToRemove.push(subtractedFeature?.properties?.['id']);
          subtractedFeature = this.subtractFeature(
            subtractedFeature,
            featureToSubtract
          );
        }
      });
      return subtractedFeature;
    });

    this.removeSubtractedFeaturesFromMap(featureIdsToRemove);
    const featuresToAddOl = this.convertGeoJsonToFeatures(subtractedFeatures);
    this.addFeaturesToMapAndStore(featuresToAddOl);
    const currentFeatures = this.selectedVectorSource.getFeatures();
    this.calculateMeasurements(currentFeatures);
  }

  /**
   * Subtracts the second feature from the first feature if both are polygons.
   * If the result is a polygon or a multipolygon, it returns the subtracted feature.
   * If the second feature is not a polygon or the subtraction result is null, it returns the first feature.
   *
   * @param {GeoJSON.Feature} feature1 - The feature from which to subtract.
   * @param {GeoJSON.Feature} feature2 - The feature to subtract.
   * @returns {GeoJSON.Feature} - The subtracted feature or the original feature if subtraction is not possible.
   */
  private subtractFeature(
    feature1: GeoJSON.Feature,
    feature2: GeoJSON.Feature
  ): GeoJSON.Feature {
    if (feature2.geometry.type === 'Polygon') {
      const subtractResult = difference(feature1 as any, feature2 as any);

      if (subtractResult !== null) {
        if (subtractResult.geometry.type === 'Polygon') {
          return TurfPolygon(subtractResult.geometry.coordinates);
        } else if (subtractResult.geometry.type === 'MultiPolygon') {
          return TurfMultiPolygon(subtractResult.geometry.coordinates);
        }
      }
    }

    return feature1;
  }

  /**
   * Removes features from the map by their IDs.
   * @param {string[]} subtractedIds - Array of feature IDs to be removed from the map.
   */
  private removeSubtractedFeaturesFromMap(subtractedIds: string[]): void {
    subtractedIds
      .filter((id) => id)
      .forEach((id) => {
        const featureToRemove =
          this.selectedVectorSource.getFeatureById(id) ?? 0;
        if (featureToRemove instanceof Feature) {
          this.selectedVectorSource.removeFeature(featureToRemove);
        }
      });
  }

  /**
   * Merges overlapping features in the given array of features.
   * It first converts the features to GeoJSON format, then merges overlapping features,
   * removes the merged features from the map, and finally adds the non-overlapping and merged features back to the map.
   *
   * @param {Feature[]} currentFeatures - An array of OpenLayers Feature objects.
   */
  mergeOverlappingFeatures(currentFeatures: Feature[]): void {
    const geojsonFeatures = this.convertFeaturesToGeoJson(currentFeatures);
    let { mergedFeature, mergedIds, nonOverlappingFeatures } =
      this.mergeFeatures(geojsonFeatures);
    this.removeMergedFeaturesFromMap(mergedIds);
    const featuresToAddOl = this.convertGeoJsonToFeatures([
      ...nonOverlappingFeatures,
      mergedFeature,
    ]);
    this.addFeaturesToMapAndStore(featuresToAddOl, true);
  }

  /**
   * Converts an array of OpenLayers Feature objects into GeoJSON Features.
   * It also adds an 'id' property to each GeoJSON Feature, which is resolved from the original OpenLayers Feature.
   *
   * @param {Feature[]} features - An array of OpenLayers Feature objects.
   * @returns {GeoJSON.Feature[]} - An array of GeoJSON Feature objects with an 'id' property.
   */
  private convertFeaturesToGeoJson(features: Feature[]): GeoJSON.Feature[] {
    return features.map((feature) => {
      const geoJsonFeature = new GeoJSON().writeFeatureObject(feature);
      geoJsonFeature.properties = {
        ...geoJsonFeature.properties,
        id: this.resolvePolygonId(feature),
      };
      return geoJsonFeature;
    });
  }

  /**
   * Merges overlapping GeoJSON features and returns the merged feature, the IDs of the merged features, and the non-overlapping features.
   * @param {GeoJSON.Feature[]} geojsonFeatures - An array of GeoJSON features.
   * @returns {Object} An object containing the merged feature, the IDs of the merged features, and the non-overlapping features.
   */
  private mergeFeatures(geojsonFeatures: GeoJSON.Feature[]): {
    mergedFeature: GeoJSON.Feature;
    mergedIds: string[];
    nonOverlappingFeatures: GeoJSON.Feature[];
  } {
    let mergedFeature = geojsonFeatures[0];
    let mergedIds = [mergedFeature.id ?? mergedFeature?.properties?.['id']];
    let nonOverlappingFeatures: GeoJSON.Feature[] = [];

    for (let i = 1; i < geojsonFeatures.length; i++) {
      if (booleanIntersects(mergedFeature, geojsonFeatures[i])) {
        mergedFeature = this.mergeFeature(mergedFeature, geojsonFeatures[i]);
        mergedIds.push(
          geojsonFeatures[i].id ?? geojsonFeatures[i]?.properties?.['id']
        );
      } else {
        nonOverlappingFeatures.push(geojsonFeatures[i]);
      }
    }

    return { mergedFeature, mergedIds, nonOverlappingFeatures };
  }

  /**
   * Merges two GeoJSON features if they are both polygons.
   * If the second feature is not a polygon, the first feature is returned.
   * If the union of the two polygons results in a polygon or multipolygon, it is returned.
   * If the union operation fails, the first feature is returned.
   *
   * @param {GeoJSON.Feature} feature1 - The first feature.
   * @param {GeoJSON.Feature} feature2 - The second feature.
   * @returns {GeoJSON.Feature} - The merged feature or the original feature if merging is not possible.
   */
  private mergeFeature(
    feature1: GeoJSON.Feature,
    feature2: GeoJSON.Feature
  ): GeoJSON.Feature {
    if (feature2.geometry.type === 'Polygon') {
      // I can't figure out the correct type of polygon
      const unionResult = union(feature1 as any, feature2 as any);

      if (unionResult !== null) {
        if (unionResult.geometry.type === 'Polygon') {
          return TurfPolygon(unionResult.geometry.coordinates);
        } else if (unionResult.geometry.type === 'MultiPolygon') {
          return TurfMultiPolygon(unionResult.geometry.coordinates);
        }
      }
    }

    return feature1;
  }

  /**
   * Removes features from the map by their IDs.
   * @param {string[]} mergedIds - Array of feature IDs to be removed from the map.
   */
  private removeMergedFeaturesFromMap(mergedIds: string[]): void {
    mergedIds.forEach((id) => {
      const featureToRemove = this.selectedVectorSource.getFeatureById(id);
      if (featureToRemove instanceof Feature) {
        this.selectedVectorSource.removeFeature(featureToRemove);
      }
    });
  }

  /**
   * Converts an array of GeoJSON features into OpenLayers Feature objects.
   *
   * @param {GeoJSON.Feature[]} features - An array of GeoJSON Feature objects.
   * @returns {Feature[]} - An array of OpenLayers Feature objects.
   */
  private convertGeoJsonToFeatures(
    features: GeoJSON.Feature[]
  ): Feature<Geometry>[] {
    return features.map(
      (feature) => new GeoJSON().readFeature(feature) as Feature<Geometry>
    );
  }
  /**
   * Adds confirmed features to the selected vector source and pushes them to the selectedFeatures array.
   * @param {Feature<Geometry>[]} features - The array of features to be added.
   * @param {boolean} featureUpdated - If the feature should be marked as updated, defaults to false.
   */
  private addFeaturesToMapAndStore(
    features: Feature<Geometry>[],
    featureUpdated = false
  ): void {
    const polygonsForStore: IDrawPolygon[] = [];
    features.forEach((feature) => {
      this.applyStyleToFeature(feature, true, !!this.draw);
      const id = this.resolvePolygonId(feature);
      feature.setId(id);
      feature.setProperties({ id });
      const geojsonStr = this.geoJsonFormat.writeFeature(feature);

      if (!geojsonStr) {
        return;
      }
      polygonsForStore.push({
        id: feature.getId()?.toString() ?? '0',
        feature: geojsonStr,
        updated: featureUpdated,
      });

      this.selectedVectorSource.addFeature(feature);
    });
    this.polygonStoreService.addPolygons(polygonsForStore);
  }

  /**
   * Zooms to the extent of the selected vector source
   */
  private zoomToFeatures(): void {
    // Get map view
    const view = this.map.getView();

    // Get source extent
    const extent = this.selectedVectorSource.getExtent();

    // Check if there are features
    const isValidExtent = extent.every(
      (n) => !isNaN(n) && n !== Infinity && n !== -Infinity
    );

    // If there are features, then fit to their extent
    if (isValidExtent) {
      const options = {
        size: this.map.getSize(), // The size of the box to fit the extent into
        padding: [0, 0, 0, 0], // An array representing margins to be cleared around the given extent
        constrainResolution: false, // If true, the view resolution (or zoom level) will be adjusted to the nearest one on the view’s resolutions list
        maxZoom: 20, // The maximum allowed zoom. This will constrain the extent if the map view would go to a smaller zoom level
        duration: 250, // The duration of the animation in milliseconds, longer is slower
        zoom: 16,
      };
      view.fit(extent, options);
    }
  }

  /**
   * Clears all vector layer features
   */
  clearAllVectorLayerFeatures(): void {
    if (this.selectedVectorSource.getFeatures().length > 0) {
      this.polygonStoreService.removeAllPolygons();
      this.selectedVectorSource.clear();
      const nullMeasurements: MeasurementsDataType = {
        totalAreaHectares: '0',
        totalPerimeterMeters: '0',
        titleNo: null,
      };
      this.appStateService.updateStateProperty(
        'measurements',
        nullMeasurements
      );
      const originalAddressPoint: AddressPointDataType = {
        ...this.appStateService.originalAddressPoint,
      };
      this.appStateService.updateStateProperty(
        'addressPoint',
        originalAddressPoint
      );
    }
  }

  /**
   * Toggles the visibility of a layer
   * @param {Object} layer - The layer to toggle visibility for
   */
  public toggleLayerVisibility(
    layer:
      | TileLayer<OsmSource | TileWMS>
      | VectorLayer<VectorSource<Feature<Geometry>>>
  ): void {
    layer.setVisible(!layer.getVisible());
  }

  /**
   * Sets up the custom tile layer
   * @return {TileWMS} The custom tile layer
   */
  private customTileSetup(): TileWMS {
    const currentYear = new Date().getFullYear();
    return new TileWMS({
      url: this.baseUrlTiles,
      params: {
        LAYERS: 'os_mastermap:OS_MAPS_AUTO',
        authkey: this.tokenInfo.authKey,
        sessionid: this.tokenInfo.sessionId,
        ispremium: 0,
      },
      serverType: 'geoserver',
      projection: 'EPSG:27700',
      attributions: [
        `<img src="https://fci-conveymap-uat.azurewebsites.net/Content/images/OSmapdataclear.png"  height="40" /> © Crown copyright and database rights: ${currentYear} Ordnance Survey 100049731`,
      ],
    });
  }

  /**
   * Finds the overlapping features of the address point feature.
   */
  private addTitlePolygon(
    titlesAtPoint: TitlesAtPointType[],
    titleNumberSelected: null | string = null
  ): void {
    const format = new WKT();

    const features = titlesAtPoint.map((item) => {
      const feature = format.readFeature(item.wkt);
      feature.setProperties({ title_no: item.titleNumber });
      return feature;
    });

    if (features.length > 0) {
      let indexToAdd = 0;
      if (titleNumberSelected) {
        indexToAdd = titlesAtPoint.findIndex(
          (item) => item.titleNumber === titleNumberSelected
        );
      }
      const featureToAdd = features[indexToAdd];
      const currentFeatures = this.selectedVectorSource.getFeatures();
      const geometryToAdd = featureToAdd.getGeometry();

      if (geometryToAdd && geometryToAdd instanceof GeometryCollection) {
        let featuresToAdd: Feature[] = [];
        const geometries = geometryToAdd.getGeometries();
        geometries.forEach((geometry) => {
          const newFeature = new Feature(geometry);
          featuresToAdd.push(newFeature);
        });
        this.addFeatureToMapAndStore(featuresToAdd, currentFeatures);
      } else {
        this.addFeatureToMapAndStore([featureToAdd], currentFeatures);
      }
      this.findPolygonsAroundAddressPoint = false;
      this.stopUpdatingPolygons = false;
    }
  }

  private addFeatureToMapAndStore(
    featuresToAdd: Feature[],
    currentFeatures: Feature[]
  ): void {
    featuresToAdd.forEach((featureToAdd) => {
      const geojsonStr = this.geoJsonFormat.writeFeature(featureToAdd);
      this.polygonStoreService.addPolygon(
        this.resolvePolygonId(featureToAdd),
        geojsonStr,
        false
      );

      const featureId = this.resolvePolygonId(featureToAdd);
      if (
        !currentFeatures.some((f) => this.resolvePolygonId(f) === featureId)
      ) {
        this.selectedVectorSource.addFeature(featureToAdd);
        featureToAdd.setStyle(this.highlightStyle);
      }
    });
    this.calculateMeasurements([...currentFeatures, ...featuresToAdd]);
  }

  /**
   * Calculates the total area in hectares and total perimeter in meters of the given features.
   * The results are then stored in the AppStateService.
   *
   * @param {Feature[]} features - An array of OpenLayers Feature objects.
   */
  calculateMeasurements(features: Feature[]): void {
    let totalAreaHectares = 0;
    let totalPerimeterMeters = 0;

    features.forEach((feature) => {
      const geometry = feature.getGeometry();
      if (geometry instanceof Polygon || geometry instanceof MultiPolygon) {
        const { area, perimeter } = this.calculateAreaAndPerimeter(geometry);
        totalAreaHectares += area;
        totalPerimeterMeters += perimeter;
      } else if (geometry instanceof GeometryCollection) {
        geometry.getGeometries().forEach((geom) => {
          if (geom instanceof Polygon || geom instanceof MultiPolygon) {
            const { area, perimeter } = this.calculateAreaAndPerimeter(geom);
            totalAreaHectares += area;
            totalPerimeterMeters += perimeter;
          }
        });
      }
    });

    const data: MeasurementsDataType = {
      totalAreaHectares: totalAreaHectares.toFixed(4),
      totalPerimeterMeters: totalPerimeterMeters.toFixed(4),
      titleNo: null,
    };
    this.stopUpdatingPolygons = true;
    this.appStateService.updateStateProperty('measurements', data);
  }

  /**
   * Calculates the total area in hectares and total perimeter in meters of the given geometry.
   * Only geometries of type Polygon or MultiPolygon are considered in the calculation.
   *
   * @param {Polygon | MultiPolygon} geometry - An OpenLayers Geometry object of type Polygon or MultiPolygon.
   * @returns {Object} An object containing the total area in hectares and total perimeter in meters.
   */
  private calculateAreaAndPerimeter(geometry: Polygon | MultiPolygon): {
    area: number;
    perimeter: number;
  } {
    let area = 0;
    let perimeter = 0;

    if (geometry instanceof Polygon) {
      area = getArea(geometry) / 10000; // Convert to hectares
      perimeter = getLength(geometry); // Length in meters
    } else if (geometry instanceof MultiPolygon) {
      geometry.getPolygons().forEach((polygon) => {
        area += getArea(polygon) / 10000; // Convert to hectares
        perimeter += getLength(polygon); // Length in meters
      });
    }

    return { area, perimeter };
  }

  /**
   * Creates an event stream from a target and event type
   * @param {any} target - The target to create the event stream from
   * @param {string} eventType - The type of event to create the stream from
   * @returns {Observable<Event>} An observable of events
   */
  private createEventStream(target: any, eventType: string): Observable<Event> {
    return fromEvent(target, eventType);
  }

  /**
   * Returns an array of features with their geometry properties
   * @param {Feature[]} features - An array of features
   * @returns {Feature[]} An array of features with their geometry properties
   */
  getGeometryProperties(features: Feature[]): Feature[] {
    return features.map((feature) => {
      const geometry = feature.getGeometry();
      const newFeature = new Feature();
      newFeature.setGeometry(geometry);
      return newFeature;
    });
  }

  /**
   * Cancels the current mapping operation.
   * If the environment is 'core', it clears the selected vector source and adds polygons to the map from the current state snapshot.
   * If the environment is 'spider', it updates the 'cancelMapping' property in the app state service.
   * If the map is currently loading, the method returns without performing any action.
   */
  cancelMapping(): void {
    if (this.selectedVectorSource.getFeatures().length > 0) {
      this.selectedVectorSource.clear();
    }
    this.appStateService.getCurrentStateSnapshot().subscribe((state) => {
      const featuresToAdd = this.deserializeFeatures(state.confirmedFeatures);
      this.addPolygonsToMap(featuresToAdd);
    });
    this.updateEastingNorthingAndCalculateMeasurements();
    if (this.environment === 'spider') {
      this.appStateService.updateStateProperty('cancelMapping', true);
    }
  }

  /**
   * Updates the easting and northing coordinates and calculates measurements for the current features.
   * If there are no current features, it adds a point to the map using the original address point's easting and northing coordinates.
   */
  updateEastingNorthingAndCalculateMeasurements() {
    const currentFeatures = this.selectedVectorSource.getFeatures();
    this.calculateMeasurements(currentFeatures);

    if (currentFeatures.length > 0) {
      this.updateEastingNorthing();
    } else {
      const newState: AddressPointDataType = {
        ...this.appStateService.originalAddressPoint,
      };
      this.appStateService.updateStateProperty('addressPoint', newState);
    }
  }

  /**
   * Resolves the polygon ID of a given feature
   * @param {Feature} feature - The feature to resolve the polygon ID for
   * @returns {string} The resolved polygon ID
   */
  resolvePolygonId(feature: Feature): string {
    const titleNo = feature.getProperties()['title_no'];
    const id = feature.getId();
    const uid = getUid(feature);
    const propertyId = feature.getProperties()['id'];

    if (propertyId) {
      return propertyId.toString();
    } else if (id) {
      return id.toString();
    } else if (uid) {
      return uid.toString();
    } else if (titleNo) {
      return titleNo.toString();
    } else {
      return new Date().getTime().toString();
    }
  }

  /**
   * Serializes the selected features and posts a message to the ConfirmedFeatures channel with the serialized features.
   */
  confirmMapping(): void {
    this.disableAllActions();
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    this.stopUpdatingPolygons = true;
    const selectedFeatures = this.getPolygonsWithIds();

    const featuresUpdated = this.checkFeaturesUpdated(selectedFeatures);
    this.appStateService.updateConfirmedFeatures(
      selectedFeatures,
      featuresUpdated,
      !this.modalOpen
    );
    this.modalOpen = !this.modalOpen;
    this.map.getInteractions().forEach((interaction) => {
      this.map.removeInteraction(interaction);
    });
  }

  /**
   * Retrieves all selected features from the vector source and assigns a unique ID to each feature.
   * The ID is either the title number of the feature or a unique identifier generated by OpenLayers.
   *
   * @returns {Feature<Geometry>[]} An array of selected features with assigned IDs.
   */
  getPolygonsWithIds(): Feature<Geometry>[] {
    const selectedFeatures = this.selectedVectorSource.getFeatures();
    selectedFeatures.forEach((feature) => {
      feature.setProperties({ id: this.resolvePolygonId(feature) });
    });
    return selectedFeatures;
  }

  /**
   * Checks if the selected features have been updated.
   *
   * This method first checks if any of the features in the current state snapshot have been updated.
   * Then, it compares the initial features with the selected features to see if there are any differences.
   * If there are differences, or if there are no selected features but there were initial features, it sets `featuresUpdated` to true.
   *
   * @param {Feature<Geometry>[]} selectedFeatures - The currently selected features on the map.
   * @returns {boolean} - Returns true if the selected features have been updated, otherwise false.
   */
  checkFeaturesUpdated(selectedFeatures: Feature<Geometry>[]): boolean {
    let featuresUpdated = false;
    this.polygonStoreService.getCurrentStateSnapshot().subscribe((snapshot) => {
      featuresUpdated = Object.values(snapshot.entities).some((entity) => {
        return entity.updated === true;
      });
    });

    let initFeatures: Feature[] = [];
    this.appStateService.getCurrentStateSnapshot().subscribe((snapshot) => {
      initFeatures = this.deserializeFeatures(snapshot.confirmedFeatures);
    });
    if (initFeatures.length > 0) {
      const initFeaturesIds = initFeatures.map((feature) => {
        return this.resolvePolygonId(feature);
      });

      for (const featureId of initFeaturesIds) {
        if (
          !selectedFeatures.some(
            (feature) => this.resolvePolygonId(feature) === featureId
          )
        ) {
          featuresUpdated = true;
          break;
        }
      }
    }

    if (selectedFeatures.length === 0 && !this.emptyFeaturesOnInit) {
      featuresUpdated = true;
    }

    return featuresUpdated;
  }

  /**
   * Stores the initial polygon features in the app state service
   */
  storePolygon(): void {
    const selectedFeatures = this.getPolygonsWithIds();
    this.calculateMeasurements(selectedFeatures);
    this.appStateService.updateConfirmedFeatures(
      selectedFeatures,
      false,
      false
    );
  }

  /**
   * Sets up the watermark feature and adds it to the vector source.
   */
  setupWaterMark(): void {
    const resolutionChange$ = this.createEventStream(
      this.map.getView(),
      'change:resolution'
    );
    const centerChange$ = this.createEventStream(
      this.map.getView(),
      'change:center'
    );

    const sizeChange$ = this.createEventStream(
      this.map.getView(),
      'change:resize'
    );

    merge(
      centerChange$.pipe(debounceTime(100)),
      sizeChange$.pipe(debounceTime(100))
    ).subscribe(() => {
      this.updateWatermarkLayer();
    });

    resolutionChange$
      .pipe(
        distinctUntilChanged(),
        tap(() => this.watermarkVectorLayer.setVisible(false)), // on every change, set the layer to invisible
        debounceTime(100)
      )
      .subscribe(() => {
        this.watermarkVectorLayer.setVisible(true); // after 100ms, set the layer to visible
        this.zoomLevel$.next(this.map.getView().getZoom() || 20);
      });

    this.updateWatermarkLayer();
  }

  /**
   * Updates the watermark layer on the map.
   */
  updateWatermarkLayer(): void {
    const extent = this.map.getView().calculateExtent(this.map.getSize());
    const polygon = fromExtent(extent);
    const watermarkfeature = new Feature(polygon);
    this.watermarkVectorSource.clear();
    this.watermarkVectorSource.addFeature(this.fillPattern(watermarkfeature));
  }

  /**
   * Fills a feature with a pattern
   * @param {Feature} f - The feature to fill
   * @returns {Feature} - The filled feature
   */
  private fillPattern(f: Feature): Feature {
    const featureStyle = f;
    const cnv = document.createElement('canvas');
    const ctx = cnv.getContext('2d');
    const img = new Image();

    img.src = watermarkPng;

    img.onload = () => {
      const pattern = ctx?.createPattern(img, 'repeat');
      featureStyle.setStyle(
        new Style({
          fill: new Fill({
            color: pattern,
          }),
        })
      );
    };
    return featureStyle;
  }

  /**
   * Add a point to the map
   * @param {[number, number]} coordinates - The coordinates of the point
   * @param {boolean} emptyFeatureData - Flag to not store feature data
   */
  public addPoint(
    coordinates: Coordinate,
    emptyFeatureData: boolean,
    setCenter: boolean
  ): void {
    this.markerVectorSource.clear();
    const point = new Point(coordinates);
    this.addressPointFeature = new Feature(point);
    this.emptyFeaturesOnInit = emptyFeatureData;
    this.markerVectorSource.addFeature(this.addressPointFeature);
    if (setCenter) {
      this.map.getView().setCenter(coordinates);
    }
  }

  /**
   * Sends a request to the API with the new bounding box
   */
  private sendRequestWithBbox(offsetAmount = 0): void {
    let extent = this.map.getView().calculateExtent(this.map.getSize());
    extent = [
      extent[0] - offsetAmount,
      extent[1] - offsetAmount,
      extent[2] + offsetAmount,
      extent[3] + offsetAmount,
    ];
    this.formattedExtent = extent + ',EPSG:27700';

    const titleUrl = this.createRequestURL(this.formattedExtent, 'TitleInfo');
    const osExtentUrl = this.createRequestURL(
      this.formattedExtent,
      'MasterMap_WFS:MasterMap_Live_topographicarea'
    );

    this.setMapSubscription(
      titleUrl,
      this.titleVectorLayer,
      this.requestTriggerTitles$
    );
    this.setMapSubscription(
      osExtentUrl,
      this.osExtentVectorLayer,
      this.requestTriggerOsExtent$
    );
  }

  /**
   * Triggers a request for the given URL
   * @param {string} requestUrl - The URL to be requested
   * @param {VectorLayer<VectorSource<Feature<Geometry>>>} targetLayer - The target layer to add features to
   */
  setMapSubscription(
    requestUrl: string,
    targetLayer: VectorLayer<VectorSource<Feature<Geometry>>>,
    subject: Subject<{
      requestUrl: string;
      targetLayer: VectorLayer<VectorSource<Feature<Geometry>>>;
    }>
  ): void {
    if (this.tokenInfo?.sessionId) {
      subject.next({ requestUrl, targetLayer });
    }
  }

  /**
   * Handles the request
   * @returns {Observable<FeatureCollection>} - The feature collection observable
   */
  handleRequest(
    subject: Subject<{
      requestUrl: string;
      targetLayer: VectorLayer<VectorSource<Feature<Geometry>>>;
    }>
  ): Observable<{
    data: FeatureCollection;
    targetLayer: VectorLayer<VectorSource<Feature<Geometry>>>;
  }> {
    return subject.pipe(
      switchMap(({ requestUrl, targetLayer }) =>
        this.httpService.getFciData(requestUrl).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 0) {
              // If the token is expired, refresh it
              return this.httpService
                .getFciToken(
                  this.addressPoint?.easting || 0,
                  this.addressPoint?.northing || 0,
                  this.addressPoint?.orderGuid || ''
                )
                .pipe(
                  switchMap((data) => {
                    // Update the token info in the app's store
                    const newState = {
                      authKey: data.MapInfoModel.AuthKey,
                      reference: data.MapInfoModel.Reference,
                      sessionId: data.MapInfoModel.SessionId,
                    };
                    this.tokenInfo = newState;
                    this.appStateService.updateStateProperty(
                      'tokenInfo',
                      newState
                    );
                    const layerName = targetLayer.get('name');

                    // Re-create the request URL with the new token
                    const newRequestUrl = this.createRequestURL(
                      this.formattedExtent,
                      layerName
                    );
                    // Retry the request with the new URL
                    return this.httpService.getFciData(newRequestUrl);
                  })
                );
            } else {
              // If the error is not due to an expired token, rethrow the error
              return throwError(() => error);
            }
          }),
          map((data: FeatureCollection) => ({ data, targetLayer }))
        )
      ),
      tap(({ data, targetLayer }) => {
        const features = this.geoJsonFormat.readFeatures(
          data
        ) as Feature<Geometry>[];

        const vectorSource = new VectorSource<Feature<Geometry>>({
          features,
        });
        const layerName = targetLayer.get('name');
        const vector: Vector = { name: layerName, source: vectorSource };
        targetLayer.setSource(vector.source);
      })
    );
  }

  /**
   *  Initializes the map projection using proj4 library
   */
  private initalizeProjection(): void {
    // Define the EPSG:27700 projection
    proj4.defs(
      'EPSG:27700',
      '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 ' +
        '+x_0=400000 +y_0=-100000 +ellps=airy ' +
        '+towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 ' +
        '+units=m +no_defs'
    );
    // Register the projection with proj4
    register(proj4);
  }

  /**
   * Creates a request URL for the WFS service
   * @param {string} bbox - The bounding box coordinates
   * @returns {string} The request URL
   */
  createRequestURL(
    bbox: string,
    typename: 'TitleInfo' | 'MasterMap_WFS:MasterMap_Live_topographicarea'
  ): string {
    if (!this.tokenInfo) {
      return '';
    }
    const params = {
      service: 'WFS',
      version: '1.1.0',
      request: 'GetFeature',
      typename,
      srsname: 'EPSG:27700',
      authkey: this.tokenInfo.authKey,
      sessionid: this.tokenInfo.sessionId,
      bbox,
      outputFormat: 'application/json',
    };

    const baseUrl =
      typename === 'TitleInfo'
        ? this.baseUrlTitleBoundries
        : this.baseUrlOsExtent;

    const url = new URL(baseUrl);

    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.append(key, value as string);
    });

    return url.toString();
  }

  /**
   * Toggles the draw mode of the map
   * @param {boolean} drawMode - Enable or disable the draw mode
   */
  private toggleDrawMode(drawMode: boolean): void {
    this.removeInteractions();
    if (!drawMode) {
      this.initSelectionMode();
      return;
    }
    this.initDrawMode();
  }

  /**
   * Removes the draw and modify interactions from the map.
   */
  private removeInteractions(): void {
    if (this.draw) {
      this.map.removeInteraction(this.draw);
    }

    if (this.modify) {
      this.map.removeInteraction(this.modify);
    }
  }

  /**
   * Initializes the selection mode of the map.
   */
  private initSelectionMode(): void {
    this.mapMode = 'select';
    this.draw = undefined;
    this.modify = undefined;
    this.setLayerStyles(this.selectedVectorLayer, false);
    this.applyStyleToAllFeatures(false);
  }

  /**
   * Applies style to features
   * @param {boolean} showVerticies - Flag indicating if verticies should be shown
   */
  private applyStyleToAllFeatures(showVerticies: boolean): void {
    this.selectedVectorSource.getFeatures().forEach((feature) => {
      this.applyStyleToFeature(feature, true, showVerticies);
    });
  }

  /**
   * Initializes the draw mode for the map.
   */
  private initDrawMode(): void {
    const source = this.selectedVectorLayer.getSource()!;
    this.setLayerStyles(this.selectedVectorLayer, true);
    this.applyStyleToAllFeatures(true);

    const modify = new Modify({ source: source });
    const draw = this.initDrawInteraction(source, 'Polygon');

    this.map.addInteraction(modify);
    this.map.addInteraction(draw);
    this.map.addInteraction(new Snap({ source: source }));

    this.draw = draw;
    this.modify = modify;
    this.mapMode = 'draw';

    // Schedule this action to run on the next macrotask queue
    // Feature has not been inserted until the next macrotask
    draw.on('drawend', (evt: DrawEvent) => {
      this.applyStyleToFeature(evt.feature, true, !!this.draw);
      asapScheduler.schedule(() => this.handleDrawEvents(evt.feature));
    });

    modify.on('modifyend', () => {
      asapScheduler.schedule(() => this.handleDrawEvents());
    });
  }

  handleDrawEvents(feature?: Feature<Geometry>) {
    if (feature) {
      feature.setId(this.resolvePolygonId(feature));
      this.setEastingNorthingToPolygonPropeties(feature);
    }

    this.updateEastingNorthing();
    const currentFeatures = this.selectedVectorSource.getFeatures();
    this.mergeOverlappingFeatures(currentFeatures);
    this.calculateMeasurements(currentFeatures);
  }

  /**
   * Sets the easting and northing properties of a polygon feature.
   * The easting and northing values are derived from the center of the polygon's extent.
   *
   * @param {Feature} feature - The polygon feature to set the properties for.
   */
  private setEastingNorthingToPolygonPropeties(feature: Feature): void {
    const geometry = feature.getGeometry();
    if (geometry) {
      const center = olExtent.getCenter(geometry.getExtent());
      const easting = center[0];
      const northing = center[1];
      feature.setProperties({ easting: easting, northing: northing });
    }
  }

  // TODO: create a style serive
  /**
   * Initializes the draw interaction for a given vector source and type
   * @param {VectorSource<Feature<Geometry>>} source - The vector source to be used
   * @param {Type} type - The type of drawing interaction
   * @returns {Draw} - The initialized Draw interaction
   */
  private initDrawInteraction(
    source: VectorSource<Feature<Geometry>>,
    type: Type
  ): Draw {
    return new Draw({
      source: source,
      type: type,
      style: this.drawingStyles[type],
    });
  }

  /**
   * Creates a new style object
   * @param {[number, number, number]} themeColorRgb - The RGB color of the theme
   * @param {number} radius - The radius of the circle
   * @param {number} width - The width of the stroke
   * @returns {Style} A new Style object
   */
  private createStyle(
    themeColorRgb: [number, number, number],
    radius: number,
    width: number
  ): Style {
    const fillColor = this.createColor(themeColorRgb, 0.2);
    const strokeColor = this.createColor(themeColorRgb, 1);

    return new Style({
      image: new CircleStyle({
        radius,
        fill: this.createFill(fillColor),
      }),
      stroke: this.createStroke(strokeColor, width),
      fill: this.createFill(fillColor),
    });
  }

  /**
   * Creates a new Fill object with the given color
   * @param {string} color - The color of the fill
   * @returns {Fill} A new Fill object with the given color
   */
  private createFill(color: string): Fill {
    return new Fill({
      color: color,
    });
  }

  /**
   * Creates a new Stroke object
   * @param {string} color - The color of the stroke
   * @param {number} width - The width of the stroke
   * @returns {Stroke} A new Stroke object
   */
  private createStroke(color: string, width: number): Stroke {
    return new Stroke({
      color: color,
      width: width,
    });
  }

  /**
   * Creates a new Circle object
   * @param {number} radius - The radius of the circle
   * @param {string} color - The color of the circle
   * @param {number} width - The width of the stroke
   * @returns {Circle} A new Circle object
   */
  private createCircle(radius: number, color: string, width: number): Circle {
    return new Circle({
      radius: radius,
      fill: this.createFill(color),
      stroke: this.createStroke(color, width),
    });
  }

  /**
   * Creates a vertex style for the given feature
   * @param {string} color - The color of the vertex
   * @param {FeatureLike} feature - The feature to create the vertex style for
   * @returns {Style} The created vertex style
   */
  private createVertexStyle(color: string, feature: FeatureLike): Style {
    return new Style({
      image: new CircleStyle({
        radius: 5,
        fill: this.createFill(color),
      }),
      geometry: function featurePoints() {
        const polygonGeometry = feature.getGeometry() as Polygon;
        const coordinates = polygonGeometry.getCoordinates()[0];
        return new MultiPoint(coordinates);
      },
    });
  }

  /**
   * Creates a color string in rgba format
   * @param {[number, number, number]} color - An array of 3 numbers representing the RGB values
   * @param {number} opacity - A number between 0 and 1 representing the opacity
   * @returns {string} The color string in rgba format
   */
  private createColor(
    color: [number, number, number],
    opacity: number
  ): string {
    return `rgba(${color}, ${opacity})`;
  }

  /**
   * Applies style to a feature
   * @param {Feature} feature - The feature to apply the style to
   * @param {boolean} selectedAddressPolygon - Whether or not the address polygon is selected
   * @param {boolean} showVerticies - Whether or not to show verticies
   */
  private applyStyleToFeature(
    feature: Feature,
    _selectedAddressPolygon: boolean,
    showVerticies: boolean
  ) {
    // For now, everything gets the same theme color
    // const colorTheme = selectedAddressPolygon
    //   ? this.addressPolygonTheme
    //   : this.customPolygonTheme;

    const colorFullOpactiy = this.createColor(this.addressPolygonTheme, 1);
    const styles = [
      new Style({
        fill: this.createFill(this.createColor(this.addressPolygonTheme, 0.2)),
        stroke: this.createStroke(colorFullOpactiy, 2),
        image: this.createCircle(5, colorFullOpactiy, 1),
      }),
      ...(showVerticies
        ? [this.createVertexStyle(colorFullOpactiy, feature)]
        : []),
    ];
    feature.setStyle(styles);
  }

  /**
   * Sets the style of a vector layer
   * @param {VectorLayer<VectorSource<Feature<Geometry>>>} layer - The layer to set the style for
   * @param {boolean} showVerticies - Whether or not to show verticies
   */
  private setLayerStyles(
    layer: VectorLayer<VectorSource<Feature<Geometry>>>,
    showVerticies: boolean
  ): void {
    const fillColor = this.createColor(this.customPolygonTheme, 0.2);
    const colorFullOpactiy = this.createColor(this.customPolygonTheme, 1);

    layer.setStyle((feature) => {
      const styles = [
        new Style({
          fill: this.createFill(fillColor),
          stroke: this.createStroke(colorFullOpactiy, 1),
          image: this.createCircle(5, colorFullOpactiy, 6),
        }),
        ...(showVerticies
          ? [this.createVertexStyle(colorFullOpactiy, feature)]
          : []),
      ];
      return styles;
    });
  }

  /**
   * Updates zoom and center of the view.
   * @param zoom Zoom.
   * @param center Center in long/lat.
   */
  updateView(zoom = 20, center: [number, number] = [0, 0]): void {
    this.map.getView().setZoom(zoom);
    this.map.getView().setCenter(center);
  }

  /**
   * Updates target and size of the map.
   * @param target HTML container.
   */
  updateSize(target = 'map'): void {
    this.map.setTarget(target);
    this.map.updateSize();
  }

  // Needs to be called after a new feature is added to the map
  updateEastingNorthing(feature: Feature | null = null): void {
    const features = this.selectedVectorSource.getFeatures();
    if (feature) {
      features.push(feature);
    }
    let largestArea = 0;
    let largestFeature: Feature | null = null;

    features.forEach((feature) => {
      const geometry = feature.getGeometry() as Polygon;
      const area = getArea(geometry);

      if (area > largestArea) {
        largestArea = area;
        largestFeature = feature;
      }
    });

    if (largestFeature) {
      const geometry = (largestFeature as Feature).getGeometry() as Polygon;
      const largestFeatureCenter = getCenter(geometry.getExtent());

      if (!this.addressPoint) {
        return;
      }
      this.clearCurrentFeaturesOnAddressPointUpdate = false;
      const newState: AddressPointDataType = {
        ...this.addressPoint,
        easting: Math.round(largestFeatureCenter[0]),
        northing: Math.round(largestFeatureCenter[1]),
      };
      this.appStateService.updateStateProperty('addressPoint', newState);
    }
  }
}
