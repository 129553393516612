import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { GeoService } from 'src/app/services/geo.service';

type ActionMode = 'floodFill' | 'drawPolygon' | 'deletePolygon' | 'none';

@Component({
  selector: 'app-controls-menu',
  templateUrl: './controls-menu.component.html',
  styleUrls: ['./controls-menu.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class ControlsMenuComponent implements OnInit, OnDestroy {
  constructor(public geoService: GeoService, private cd: ChangeDetectorRef) {}
  zoomLevel = 20;
  zoomSubscription?: Subscription;
  mapActionMode: ActionMode = 'none';

  ngOnInit(): void {
    this.zoomSubscription = this.geoService.zoomLevel$.subscribe(
      (zoomLevel) => {
        this.zoomLevel = zoomLevel;
        this.cd.detectChanges();
      }
    );
  }

  toggleMapActionMode(mode: ActionMode) {
    if (this.mapActionMode !== mode) {
      this.mapActionMode = mode;
    } else {
      this.mapActionMode = 'none';
    }

    this.geoService.resolveMapAction(this.mapActionMode);
  }

  drawPolygon() {
    this.toggleMapActionMode('drawPolygon');
  }

  floodFill() {
    this.toggleMapActionMode('floodFill');
  }

  deletePolygon() {
    this.toggleMapActionMode('deletePolygon');
  }

  recentre() {
    this.geoService.centerMapOnAddressPointFeature();
  }

  undoRedo(action: 'undo' | 'redo') {
    if (action === 'undo') {
      this.geoService.undoMapAction();
    } else if (action === 'redo') {
      this.geoService.redoMapAction();
    }
  }

  setZoomLevel(level: number) {
    this.zoomLevel = level;
    this.geoService.map.getView().setZoom(this.zoomLevel);
    this.geoService.updateWatermarkLayer();
  }

  increaseOrDecreaseZoom(delta: number) {
    if (
      delta === null ||
      (this.zoomLevel === 24 && delta === 1) ||
      (this.zoomLevel === 16 && delta === -1)
    ) {
      return;
    }
    this.zoomLevel += delta;
    this.geoService.map.getView().setZoom(this.zoomLevel);
    this.geoService.updateWatermarkLayer();
  }

  clearAll() {
    this.geoService.clearAllVectorLayerFeatures();
  }

  ngOnDestroy(): void {
    this.zoomSubscription?.unsubscribe();
  }
}
