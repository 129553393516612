import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-satellite-view-button',
  template: `
    <div class="toggle-satellite-view-button ol-unselectable em-btn" (click)="toggleView()">
      <button type="button" >
        {{ satelliteView ? 'Default View' : 'Satellite View' }}
      </button>
    </div>
  `,
  standalone: true,
})
export class ToggleSatelliteViewButtonComponent {
  private _isSatelliteView = false;

  @Output() toggleClick = new EventEmitter<boolean>();
  @Input() set satelliteView(value: boolean) {
    this._isSatelliteView = value;
  }

  get satelliteView(): boolean {
    return this._isSatelliteView;
  }

  toggleView(): void {
    this.toggleClick.emit(!this.satelliteView);
  }
}
