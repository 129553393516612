<div class="em-menu-container" id="control-menu">
  <div class="em-menu-left">
    <button
      type="button"
      class="em-btn"
      [ngClass]="{ active: mapActionMode === 'drawPolygon' }"
      (click)="drawPolygon()"
    >
      Draw Polygon
    </button>
    <button
      type="button"
      class="em-btn"
      [ngClass]="{ active: mapActionMode === 'floodFill' }"
      (click)="floodFill()"
    >
      Flood Fill
    </button>
    <button type="button" class="em-btn" (click)="recentre()">Recentre</button>
    <button type="button" class="em-btn" (click)="undoRedo('undo')">
      Undo
    </button>
    <button type="button" class="em-btn" (click)="undoRedo('redo')">
      Redo
    </button>
  </div>
  <div class="em-slider-container">
    <button
      type="button"
      class="em-slider-dec"
      (click)="increaseOrDecreaseZoom(-1)"
    ></button>

    <input
      #zoomInput
      (input)="(zoomInput.value)"
      type="range"
      class="em-slider"
      min="16"
      max="24"
      step="1"
      [value]="zoomLevel"
      (change)="setZoomLevel(+zoomInput.value)"
    />

    <button
      type="button"
      class="em-slider-inc"
      (click)="increaseOrDecreaseZoom(+1)"
    ></button>
  </div>
  <div class="em-menu-right">
    <button
      type="button"
      class="em-btn"
      [ngClass]="{ active: mapActionMode === 'deletePolygon' }"
      (click)="deletePolygon()"
    >
      Delete Polygon
    </button>
    <button type="button" class="em-btn" (click)="clearAll()">Clear All</button>
  </div>
</div>
