<div class="site-information">
  <div class="header">Site Information</div>
  <div class="site-info">
    <div class="item" *ngIf="defaultSiteData && defaultSiteData.area">
      Site Hectares:<span>
        {{ defaultSiteData.area.toFixed(2) }}
      </span>
    </div>
    <div class="item" *ngIf="currentSiteData && currentSiteData[0]">
      Easting:<span>
        {{ currentSiteData[0].toFixed(1) }}
      </span>
    </div>
    <div class="item" *ngIf="currentSiteData && currentSiteData[1]">
      Northing:<span>
        {{ currentSiteData[1].toFixed(1) }}
      </span>
    </div>
    <div class="item" *ngIf="defaultSiteData && defaultSiteData.perimeter">
      Site Perimeter:<span> {{ defaultSiteData.perimeter.toFixed(1) }}m </span>
    </div>
  </div>
</div>
