<div
  id="maptool-conatiner"
  class="maptool-container"
  *ngIf="this.geoService.floodFillToggle"
>
  <div class="maptool-title">Map Tool</div>
  <div class="switch-container">
    <label class="switch">
      <input
        type="checkbox"
        [value]="titleBoundaryToggle"
        [(ngModel)]="titleBoundaryToggle"
        (ngModelChange)="geoService.resolveBoundryToggle($event, 'title')"
      />
      <span class="slider round"></span>
    </label>
    <label class="switch-label"> Title<br />Boundary </label>
  </div>
  <div class="switch-container">
    <label class="switch">
      <input
      type="checkbox"
      [value]="osExtentToggle"
      [(ngModel)]="osExtentToggle"
      (ngModelChange)="geoService.resolveBoundryToggle($event, 'osExtent')"
    />
      <span class="slider round"></span>
    </label>
    <label class="switch-label"> OS Extent </label>
  </div>
  <div class="maptool-info">
    <div *ngIf="titleBoundaryToggle; else noTitleBoundary">Double click the map to select Title Boundary</div>
    <ng-template #noTitleBoundary>
      Double click the map to select Os Extent.
    </ng-template>
  </div>
</div>
