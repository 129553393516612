import { filter, skip } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { GeoService } from './services/geo.service';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './services/http.service';
import { EditMapWidgetComponent } from './components/edit-map-widget/edit-map-widget.component';
import { PolygonStoreService } from './services/polygon-store.service';
import { AppStateService } from './services/app-state.service';
import deepEqual from 'deep-equal';
import { Subscription } from 'rxjs';
import { StateProps, StatePropsType } from './models/message-data.models';
import { SiteInformationComponent } from './components/site-information/site-information.component';
import { Coordinate } from 'ol/coordinate';
import { ControlsMenuComponent } from './components/controls/controls-menu/controls-menu.component';
import { ToggleSatelliteViewButtonComponent } from './components/controls/toggle-satellite-view-button.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    EditMapWidgetComponent,
    SiteInformationComponent,
    ControlsMenuComponent,
    ToggleSatelliteViewButtonComponent,
  ],
  providers: [GeoService, AppStateService, HttpService, PolygonStoreService],
})
export class AppComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() mode: 'viewOnly' | 'select' = 'select';
  @Input() environment: 'core' | 'spider' = 'spider';
  @Input() state?: StatePropsType;
  @Output() notify: EventEmitter<StatePropsType> =
    new EventEmitter<StatePropsType>();
  coordinate: Coordinate = [];

  private appStateSubscription!: Subscription;

  constructor(
    public geoService: GeoService,
    private appStateService: AppStateService
  ) {
    // We skip 1 here because we don't need to emit the updated
    // state while the AngularJS app is initializing
    this.appStateSubscription = this.appStateService.store$
      .pipe(
        skip(1),
        filter((newState) => !deepEqual(newState, this.state))
      )
      .subscribe((newState) => {
        this.notify.emit(newState);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mode']) {
      this.mode = changes['mode'].currentValue;
      this.geoService.mapMode = changes['mode'].currentValue;
      this.geoService.updateControls();
      this.geoService.updateInteractions();
      this.geoService.setTitleLayerOpacity();
      this.geoService.setupFeatureRequests();
      if (this.mode === 'select') {
        this.geoService.map.on('pointermove', (event) => {
          this.coordinate = event.coordinate;
        });
      }
    }
    if (changes['environment']) {
      this.environment = changes['environment'].currentValue;
      this.geoService.environment = changes['environment'].currentValue;
    }
    if (changes['state']) {
      if (
        changes['state'].currentValue === 'stateObject' ||
        changes['state'].currentValue === undefined
      ) {
        return;
      }
      // Validate the state object using Zod
      const validatedState = StateProps.parse(changes['state'].currentValue);
      this.appStateService.updateStore(validatedState);
    }
  }

  ngAfterViewInit(): void {
    this.geoService.updateView();
    this.geoService.updateSize();
    this.geoService.setupWaterMark();
  }

  ngOnDestroy(): void {
    this.appStateSubscription.unsubscribe();
  }
}
