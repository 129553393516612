import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import { Subscription } from 'rxjs';
import { ISiteInformationData } from 'src/app/models/message-data.models';
import { AppStateService } from 'src/app/services/app-state.service';

@Component({
  selector: 'app-site-information',
  templateUrl: './site-information.component.html',
  styleUrls: ['./site-information.component.scss'],
  imports: [CommonModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteInformationComponent implements OnInit, OnDestroy {
  defaultSiteData: ISiteInformationData = {
    area: 0,
    northing: 0,
    easting: 0,
    perimeter: 0,
  };

  siteInformationSubscription: Subscription | undefined;
  @Input('coordinate') currentSiteData: Coordinate = [];
  constructor(
    private appStateService: AppStateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.siteInformationSubscription =
      this.appStateService.measurementsAndAddressPoint$.subscribe(
        ([measurements, addressPoint]) => {
          this.defaultSiteData.area = +(measurements.totalAreaHectares ?? 0);
          this.defaultSiteData.perimeter = +(
            measurements.totalPerimeterMeters ?? 0
          );
          this.defaultSiteData.easting = +(addressPoint.easting ?? 0);
          this.defaultSiteData.northing = +(addressPoint.northing ?? 0);

          if (!this.currentSiteData || this.currentSiteData?.length === 0) {
            this.currentSiteData[0] = this.defaultSiteData?.easting;
            this.currentSiteData[1] = this.defaultSiteData?.northing;
          }
          this.changeDetectorRef.detectChanges();
        }
      );
  }

  ngOnDestroy(): void {
    this.siteInformationSubscription?.unsubscribe();
  }
}
